import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="title">GG Labs</h1>
        <p className="description">
          We provide consulting services and open source software development
          services, with globally distributed team members in Europe, North and
          South America, Asia and Africa.
        </p>
        <a href="mailto:hello@gglab.tech" className="button">
          Contact Us
        </a>
      </header>
    </div>
  );
}

export default App;
